import { isClient } from '@qctsw/utils'
import type {
  FetchResponseType,
  Pagination,
  PaginationOption,
  PaginationType,
} from './types'
import { LOAD_STATE } from '@/shared/types'
import type { PaginationParams, PaginationResponse } from '@/server/request/types'

export type PaginationFn = ReturnType<typeof usePagination>
export type OmitPageParam<T extends PaginationParams> = Omit<T, 'pageSize' | 'currentPage'>
/**
 * 分页: 上拉请求 | 页码请求
 */
export function usePagination<
  P extends PaginationParams,
  Res extends PaginationResponse<any>,
  OP extends Record<string, any>,
  >(option: PaginationOption<P, Res, OP>) {
  const loadType: PaginationType = option?.type || 'up'
  const { pageNum: currentPage = 1, pageSize = 10 } = option

  const {
    request,
    key,
    onlyClient = false,
    throwError,
  } = option

  if (!request)
    throw new Error('usePagination: request is required')

  const pagination = reactive<Pagination>({
    page: currentPage, // 当前页数
    pageSize, // 每页数
    itemCount: 0, // 总条数
    pageCount: 0, // 总页数
    status: LOAD_STATE.INIT,
    params: {},
  })

  function initPagination(pageNum = 1) {
    setPage(pageNum)
    pagination.pageSize = option?.pageSize || 10
    pagination.itemCount = 0
    pagination.pageCount = 0
    pagination.status = LOAD_STATE.INIT
  }

  function setPagination<T = unknown>(data: PaginationResponse<T>) {
    setPage(data.currentPage)
    pagination.pageSize = data.pageSize
    pagination.itemCount = data.total
    pagination.pageCount = data.pages
  }
  function setParams(params: Record<string, string | number>) {
    pagination.params = params || {}
  }

  // 设置分页请求状态
  function setPaginationStatus(status: LOAD_STATE) {
    pagination.status = status
  }
  // 判断是否能够加载更多
  function canLoadMore() {
    // 上拉加载时
    const statusCanLoadMore = !![LOAD_STATE.INIT, LOAD_STATE.LOAD_MORE, LOAD_STATE.ERROR].includes(pagination.status)
    // 页码分页
    const pageCanLoadMore = pagination.page <= pagination.pageCount || [LOAD_STATE.INIT, LOAD_STATE.ERROR].includes(pagination.status)

    // console.log(statusCanLoadMore, pageCanLoadMore, pagination.value.status)
    return loadType === 'up' ? (statusCanLoadMore && pageCanLoadMore) : pageCanLoadMore
  }

  function setPage(page = 1) {
    pagination.page = page
  }

  async function clientHandler(params: P, options?: OP) {
    return await request(params, options)
  }
  async function serverHandler(params: P, options?: OP) {
    if (!key)
      return await clientHandler(params, options)

    const asyncData = await useAsyncData(key, () => request(params, options))

    if (!asyncData)
      return

    const { result, error } = useNuxtAsyncSimpleHandle(asyncData)
    // 暴露错误，以通过catch统一处理
    if (error.value)
      throw error.value

    return result
  }

  const errorHandler = (e: unknown) => {
    setPaginationStatus(LOAD_STATE.ERROR)
    if (typeof throwError === 'function')
      throwError(e)

    else if (throwError)
      throw e

    else
      recordError(e)
  }

  async function loadPaginationData(page?: number, options?: OP): Promise<{
    response: FetchResponseType<Res>
    list: Res['list']
  } | null> {
    // 如果有页码传递进来，设置页码
    if (loadType !== 'up')
      page && setPage(page)
    // 如果是上拉加载，当加载错误是不修改当前页码
    else if (pagination.status !== LOAD_STATE.ERROR)
      page && setPage(page)

    if (!canLoadMore() && loadType !== 'search')
      return null

    const noAddPageCount = loadType !== 'up' || [LOAD_STATE.INIT, LOAD_STATE.ERROR].includes(pagination.status)
    try {
      const page = noAddPageCount ? pagination.page : pagination.page + 1
      setPage(page)
      // 设置 loading 状态
      setPaginationStatus(LOAD_STATE.LOADING)
      const params = { pageSize: pagination.pageSize, currentPage: page, ...pagination.params }

      const nuxt = useNuxtApp()
      const isServerSide = nuxt.isHydrating || !isClient

      let response: FetchResponseType<Res> | undefined

      // 发送请求
      if (!onlyClient && key && isServerSide)
        response = await serverHandler(params, options)
      else
        response = await clientHandler(params, options)

      if (!response) {
        setPaginationStatus(LOAD_STATE.END)
        return null
      }

      const { data } = response
      // 设置分页状态
      setPaginationStatus(data.currentPage < data.pages ? LOAD_STATE.LOAD_MORE : LOAD_STATE.END)
      // 设置分页具体信息
      setPagination(data)

      return {
        response,
        list: response.data.list as Res['list'],
      }
    }
    catch (error) {
      errorHandler(error)
      return null
    }
  }

  return {
    loadType,
    pagination,
    setParams,
    setPage,
    initPagination,
    setPagination,
    setPaginationStatus,
    canLoadMore, // 判断是否能够加载更多
    loadPaginationData,
  }
}
